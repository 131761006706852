<template>
	<div id="app" :class="vueAppClasses">
		<router-view @setAppClasses="setAppClasses" />

    <!-- session alert -->
    <div 
      class="modal fade" 
      id="alertSessionModal" 
      data-bs-backdrop="static" 
      data-bs-keyboard="false" 
      tabindex="-1" 
      aria-labelledby="alertSessionModalLabel" 
      aria-hidden="true">
      
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-light">
          
          <div class="p-4 d-flex justify-content-center">
            <h5 
              class="alert-session-title" 
              id="alertSessionModalLabel">Alerta cierre de sesión</h5>
          </div>

          <div class="p-4 px-20">
            <div class="alert-session-message">
              El cierre automático de sesión se llevará a cabo en: <span id="left-time-alert">00:00</span> segundos
            </div>
          </div>
          
          <div class="py-4 px-20 d-flex flex-column">
            <button 
              @click="resetTimerProps()"
              type="button" 
              class="btn btn-session-active my-1">Mantener sesión activa</button>
            <button 
              @click="logout()"
              type="button" 
              class="btn btn-session-close my-1" 
              data-bs-dismiss="modal">Cerrar sesión</button>
          </div>

        </div>
      </div>

    </div>
    <!-- session alert -->

	</div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'

export default {
  data() {
    return {
      vueAppClasses: [],
      
      // session alert
      startTime: null,
      actualTime: null,
      leftTime: null,
      alertTimerModal: null,
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr")
    },

    '$route': function(newValue, oldValue) { // session alert

    // console.log( 'route app.vue' );

      /**
       * Cuando existe un cambio en la url(páginas), reviso la sesión actual.
       * Verificacion a nivel global
       * 
       * INFO: Desde aqui se podria reiniciar los temporizadores desde diferentes pestañas
       */

      /**
       * Si ingresa al componente LoadingPage.vue se considera proceso de multilogin, no se ejecuta el openTimer que 
       * verifica la sesion en cada redirección
       * 
       * Razón: El proceso de inicio de sesión del multilogin es más lento, lleva más redirecciones. El openTimer 
       * redirecciona antes porque no se crea la data de sesión en el localstorage cuando se redirecciona por primera vez.
       */
      // url permitidas, no ejecutan el openTimer
      let allowUrlPages = ['recuperarcontrasena', 'cambiarcontrasena'];

      if (this.$route && this.$route.name == 'loading') {
        localStorage.setItem('multiLoginLoading', true);
      } else {
        localStorage.setItem('multiLoginLoading', false); // cuando no es multilogin, crear la propiedad
      }

      /**
       * Si no existe un proceso de multilogin y no es una pagina permitida, se ejecuta el openTimer como normalmente
       */
      if (localStorage.getItem('multiLoginLoading') == 'false' && (this.$route && !allowUrlPages.includes(this.$route.name))) {
        // console.log( '$route' );
        // console.log( this.$route ); // debug
        // console.log( 'Inicio sin multilogin' );
        this.openTimer();
      }
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      }
      else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      }
      else {
        if (document.body.className.match('theme-dark'))      document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },


    // TODO: Se retiran los clg despues que valide QA
    // session alert
    inactivitySession() { 
      // console.log( 'Inicia el timer' );

      // instanciar modal
      this.alertTimerModal = new bootstrap.Modal(document.getElementById('alertSessionModal'), {
        backdrop: 'static', 
        keyboard: false
      });


      // timer | 1000 * 60 * 60 = 1 hour
      this.startTime = new Date().getTime() + 1000 * 60 * 60; // 60 minutes
      // console.log( 'TiempoBase: ', this.startTime ); // debug

      const timeHtml = document.getElementById("left-time-alert");

      const timer = setInterval(() => {
        // console.log( 'timer setInterval' );

        this.actualTime = new Date().getTime();
        this.leftTime = this.startTime - this.actualTime;

        const minutes = this.leftTime % (1000 * 60 * 60) / (1000 * 60);
        const seconds = this.leftTime % (1000 * 60) / 1000;

        const minutesTens = Math.floor(minutes / 10);
        const minutesUnits = Math.floor(minutes % 10);
        const secondsTens = Math.floor(seconds / 10);
        const secondsUnits = Math.floor(seconds % 10);

        // Mostrar alerta con tiempo cuando quedan 3min y sea mayor a 1 segundo restante
        if (minutes < 3 && this.leftTime > 0) {
          this.alertTimerModal.show();
        }

        if (this.leftTime > 0) {
          const timeFormat = minutesTens + '' + minutesUnits + ':' + secondsTens + '' + secondsUnits
          // console.log(minutesTens + '' + minutesUnits + ':' + secondsTens + '' + secondsUnits );
          timeHtml.innerHTML = timeFormat; // set time into html span
        } else {
          // es menor que 0
          clearInterval(timer);
          // sesion
          if (this.checkSession()) { // finalizar solo una vez para todas las pestañas
            // console.log('SesionFinalizada');
            this.logout();
          }
        }

        /**
         * Detecta cada segundo si se reinician los props del timer desde otro componente, sin importar la ubicacion
         */
        this.checkResetTimerProps();

        /**
         * Por si abre dos o más pestañas con diferentes instancias de le app en memoria
         * Reviso cada segundo la sesión (Esto funciona en el equipo del cliente)
         * Si es invalida en una pestaña cierro la sesion en todas las pestañas del navegador
         */
        this.checkSession();

      }, 1000);
      
    },
    checkResetTimerProps() {
      // console.log( 'Buscando reinicio de propiedades de timer' );
      if (localStorage.getItem('resetTimer')) {
        if (localStorage.getItem('resetTimer') == 'true') { // es true
          this.resetTimerProps();
          // console.log( 'valor: ', localStorage.getItem('resetTimer') );
          // pasar a false el resetTimer
          setTimeout(() => {
            localStorage.setItem('resetTimer', false);
          }, 10);
        }
      }
    },
    resetTimerProps() {
      this.startTime = new Date().getTime() + 1000 * 60 * 60;
      this.actualTime = new Date().getTime();
      this.leftTime = this.startTime - this.actualTime;
      // console.log( 'resetTimerProps' );

      if (this.alertTimerModal) { // cerrar modal si usan el botón mantener sesion activa
        this.alertTimerModal.hide();
      }
    },
    endTimeProps() {
      this.startTime = null;
      this.actualTime = null;
      this.leftTime = null;
      if (this.alertTimerModal) { // cerrar todos los modales
        this.alertTimerModal.hide();
      }

      // console.log( 'endTimeProps' );
    },
    openTimer() {
      /**
       * Si es una sesion valida inicia el temporizador,
       * Si es una sesion valida y con temporizador en memoria(misma pestaña) reiniciar propiedades de temporizador
       */
      // console.log( 'openTimer' );
      if (this.checkSession()) { // es una sesion valida
        // console.log( 'Primera condicion' );
        // hay un temportizador en memoria
        if (this.leftTime > 0) {
          // console.log( 'Segunda condicion' );
          this.resetTimerProps();
        } else {
          // inicia temporizador
          // console.log( 'Tercera condicion' );
          this.inactivitySession();
        }
      }
    },
    checkSession() {
      // Verificar si existe una sesion valida
      if( !JSON.parse(localStorage.getItem('userInfo')) ) {
        // console.log( 'Sin sesion válida' );
        this.$router.push('/login').catch(() => {});
        this.endTimeProps(); // valor null default, por multiple tabs

        return false;
      } else {
        // console.log( 'Con sesion válida' );
        return true;
      }
    },
    logout() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      const idTokenMultilogin = userInfo.IdToken
      const isSesionMultilogin = userInfo.SesionMultilogin
      if( idTokenMultilogin && isSesionMultilogin ) {
        this.ActualizaSesionMultilogin();
      } else {
        this.$cookie.delete('key');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('socData');
        this.$router.push('/login').catch(() => {})
      }

    },
    ActualizaSesionMultilogin() {
      let self = this;
      let objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'CerrarSesionMultilogin',
        objEjecutivo: {
          EMail: JSON.parse(localStorage.getItem('userInfo')).EMail
        }
      }
      this.$axios.post('/', objRequestAutentica,{ headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(response => {
        if (response.data.intEstatus == 0) {
          this.$cookie.delete('key');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('socData');
          
          if (this.$store.state.AppActiveUser.TipoEjecutivoV3 == 'Inmo') {
            window.location = 'https://authext.sisec.mx/connect/endsession?id_token_hint='+response.data.objContenido.IdToken+'&post_logout_redirect_uri=https://cotizador.socasesores.com/app/login/'
          } else {
            window.location = 'https://auth.sisec.mx/connect/endsession?id_token_hint='+response.data.objContenido.IdToken+'&post_logout_redirect_uri=https://cotizador.socasesores.com/app/login/'
          }
        } else {
          this.$vs.notify({
            title: 'Ocurrio un error en Cerrar Sesion Multilogin',
            text: response.data.strError,
            color: 'danger',
            position: 'top-right'
          })
        }
      }).catch(function (error) {
        self.$vs.notify({
          title: 'Ocurrio un error de sistema',
          text: error,
          color: 'danger',
          position: 'top-right'
        })
      })
    }, // session alert
  },
  mounted() {
		this.$vs.theme({
			primary: '#4AA84F'//'#2C50A0' 4AA84F 4BA84F
		})
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  async created() {

    let dir = this.$vs.rtl ? "rtl" : "ltr"
    document.documentElement.setAttribute("dir", dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
}

</script>
<style media="screen">
@media print {
body::-webkit-scrollbar{
    display: none;
  }
.router-view{
  padding: 0px;
  margin:0px;
}
}
 
	select{
		padding: .6rem !important;
		border: 1px solid rgba(0, 0, 0, 0.2);
	}
	.vdp-datepicker {
    position: inherit !important;
	}

   
.form-check{
    padding: 2px !important;
    margin: 2px !important;
    float: left;
    font-size: 12px;
}
.form-check-label {
    font-size: 12px;
    color: #006D4E;
    margin-top: 0px !important;
    margin-right: 10px;
}

    .form-floating>.form-control, .form-floating>.form-select {
        height: calc(2.5rem + 2px) !important;
        line-height: 1.25;}

        
    .img-form{width:187px !important;}

  video {
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0;
  height: 118vh;
  width: 100%;
}
 #myVideo {
    position:absolute;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    padding: 0;
  }
 
  .contenido {
    position: relative;
    width: 100%;
    min-height: 100%;
    padding: 0px;
  }

/* session alert*/
.alert-session-title {
  color: #006d4e;
  font-weight: bold;
  font-size: 1.3rem;
}
.alert-session-message {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
.btn-session-active, .btn-session-close {
  color: #fff;
  background-color: #006d4e;
  border-color: #006d4e;
}
.btn-session-active:hover, .btn-session-close:hover {
  color: #eee;
}
</style>
